import React, { useEffect, useState } from 'react';
import lscache from 'lscache';

import {
  identityAuthSubscribe,
  decodeBrandData,
  identityBookmarkAction,
} from '../unified-identity/utils';

const withBookmarkData = () => (WrappedComponent) => {
  const {
    nbc: {
      identity,
      dataLayer: {
        thisRequestType = '',
      },
    },
    wp: {
      apiFetch,
    } = {},
  } = window;

  let customerId = '';

  const DataProvider = (props) => {
    const [data, updateData] = useState({
      noids: [],
      items: [],
    });
    const [meta, updateMeta] = useState({
      totalBookmarks: 0,
      totalPosts: 0,
      totalVideos: 0,
    });
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [updatedNoids, storageUpdatedNoids] = useState([]);

    const processJSON = async () => {
      const {
        token,
      } = identity;

      // eslint-disable-next-line no-underscore-dangle
      customerId = identity.getUserDetails()._id;
      const storedUserData = lscache.get(customerId);

      if ('singular' === thisRequestType && storedUserData) {
        updateData({
          noids: storedUserData,
          items: [],
        });
        setIsLoading(false);
      } else {
        if (['account', 'cuenta'].includes(thisRequestType)) {
          const savedNoid = decodeBrandData();
          if (savedNoid) {
            await identityBookmarkAction(savedNoid, false);
          }
        }

        apiFetch({
          path: 'nbc/unified/bookmarks',
          method: 'post',
          headers: {
            'x-auth-token': token,
          },
          data: {
            customerId,
            page: 1,
            per_page: 100,
          },
        })
          .then(({
            meta: {
              total_bookmarks: totalBookmarks,
              total_posts: totalPosts,
              total_videos: totalVideos,
            },
            data: response,
          }) => {
            updateData(response);
            updateMeta({
              totalBookmarks,
              totalPosts,
              totalVideos,
            });
            setIsLoading(false);

            lscache.set(customerId, response.noids, 60);
          });
      }
    };

    const handleStorageChange = (event) => {
      if (event.key === `lscache-${customerId}`) {
        const bookmarkedData = JSON.parse(event.newValue);
        if (null !== bookmarkedData) {
          storageUpdatedNoids(bookmarkedData);
        }
      }
    };

    const flushData = () => {
      updateData({
        noids: [],
        items: [],
      });
      lscache.remove(customerId);
    };

    const getData = () => {
      processJSON();
    };

    const updateNoids = (noids) => {
      if (null !== noids) {
        updateData((prevState) => ({
          ...prevState,
          noids,
        }));
      }
    };

    useEffect(() => {
      const authenticationChange = () => {
        identity.validateSession()
          .then((isValid) => {
            if (! isValid) {
              setIsAuthenticated(isValid);
              flushData();
              document.body.classList.remove('identity-authenticated');
            }
          });
      };

      identityAuthSubscribe((status) => {
        if (['unauthenticated', 'authenticated'].includes(status)) {
          if ('unauthenticated' === status) {
            setIsLoading(false);
          }
          setIsAuthenticated('authenticated' === status);
        }
      });

      window.addEventListener('storage', handleStorageChange);
      window.addEventListener('focus', authenticationChange);

      return () => {
        window.removeEventListener('storage', handleStorageChange);
        window.removeEventListener('focus', authenticationChange);
      };
    }, []);

    useEffect(() => {
      if (isAuthenticated) {
        getData();
      } else {
        flushData();
      }
    }, [isAuthenticated]);

    useEffect(() => {
      updateData((prevState) => ({
        ...prevState,
        noids: updatedNoids,
      }));
    }, [updatedNoids]);

    return (
      <WrappedComponent
        {...props}
        isAuthenticated={isAuthenticated}
        isLoading={isLoading}
        updateNoids={updateNoids}
        data={data}
        meta={meta}
      />
    );
  };

  return DataProvider;
};

export default withBookmarkData;
