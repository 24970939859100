import React, { useEffect, useState } from 'react';

import {
  identityLogin,
  identityBookmarkAction,
} from '../unified-identity/utils';

import unifiedTrackClick from '../unified-identity/tracking';

import { formatSyndicatedID } from '../nbc-jw-player/utils';

const {
  wp: {
    i18n: {
      __,
    },
  },
  nbc: {
    brand,
  },
} = window;

/**
 * Bookmark component.
 */
const NBCBookmark = (props) => {
  const {
    isAuthenticated = false,
    isLoading = true,
    noid,
    isVideo = false,
    data: {
      noids = [],
    },
    updateNoids,
  } = props;

  const [isSaved, setIsSaved] = useState(noids.includes(noid));
  const [isButtonDisabled, setButtonDisabled] = useState(isLoading);

  useEffect(() => {
    setIsSaved(noids.includes(noid));
  }, [noids, noid]);

  useEffect(() => {
    setButtonDisabled(isLoading);
  }, [isLoading]);

  const loginCta = isVideo ? __('Log in or create a free profile to save videos', 'nbc') : __('Log in or create a free profile to save articles', 'nbc');
  const tabParam = isVideo ? '?tab=videos' : '';

  return (
    <div className={`identity-profile__bookmark ${! isAuthenticated && ! isLoading ? 'identity-profile__has-cta' : ''}`}>
      {(! isAuthenticated && ! isLoading) &&
        <div className="identity-profile__cta">
          {loginCta}
        </div>
      }
      <button
        className={`identity-profile__bookmarker identity-profile__is${isAuthenticated && isSaved ? 'Saved' : 'Unsaved'}
          ${! isAuthenticated ? 'identity-profile__unAuthenticated' : ''}`}
        disabled={isButtonDisabled}
        onClick={() => {
          if (isAuthenticated) {
            setButtonDisabled(true);
            identityBookmarkAction(noid, isSaved, (res) => {
              setIsSaved(res && ! isSaved);
              setButtonDisabled(false);
            }, updateNoids);
            unifiedTrackClick(`${isVideo ? 'video - ' : 'article - '}${formatSyndicatedID(noid)}`, `item ${isSaved ? 'unsave' : 'save'} bar`, formatSyndicatedID(noid));
          } else {
            unifiedTrackClick(`${isVideo ? 'video - ' : 'article - '}${formatSyndicatedID(noid)}`, 'item save login bar', formatSyndicatedID(noid));
            identityLogin(noid);
          }
        }}
        type="button"
      >
        BOOKMARKER
      </button>
      {(isAuthenticated && isSaved) &&
        <div className="identity-profile__toSaved">
          <a
            className="identity-profile__goTo"
            href={`${('telemundo' === brand ? '/cuenta/' : '/account/')}${tabParam}`}
            onClick={() => {
              unifiedTrackClick(`${isVideo ? 'video - ' : 'article - '}${formatSyndicatedID(noid)}`, 'go to saved', formatSyndicatedID(noid));
            }}
          >
            {__('Go to saved', 'nbc')}
          </a>
        </div>
      }
    </div>
  );
};

export default NBCBookmark;
