/* eslint-disable no-console */
/**
 * Initialize Unified Identity SDK
 */
import reactComponentManager from 'utils/reactComponentManager';
import domContentLoaded from 'utils/domContentLoaded';
import NBCBookmark from 'components/unified-bookmark/index.tsx';

import 'scss/unified-bookmark/index.scss';

domContentLoaded(() => {
  const {
    nbc: {
      identity,
    },
  } = window;

  identity.on('status', (status) => {
    if ('initialized' === status) {
      reactComponentManager({
        NBCBookmark,
      });
    }
  });
});
